<template>
    <div>
      <v-divider></v-divider>
      <v-layout wrap fill-height>
        <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down">
          <v-navigation-drawer
            class="fixedSidebar"
            clipped
            width="100%"
            height="100vh"
            permanent
            style="background-color: #fafafa"
          >
            <v-layout style="background-color: #fff; height: 60px !important">
              <v-flex xs12 pl-4 align-self-center>
                <v-img
                  contain
                  width="150px"
                  height="150px"
                  src="../assets/logo.jpg"
                ></v-img>
              </v-flex>
            </v-layout>
  
            <v-list dense nav>
              <v-list-item
                v-for="item in items"
                :key="item.title"
                style="letter-spacing: 0.63px"
                link
                class="pr-0"
                :to="item.path"
              >
                <v-list-item-icon>
                  <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon>
                  <v-list-item-title
                    style="
                      font-size: 14px;
                      font-family: poppinsmedium;
                      color: #9f9f9f;
                    "
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-icon>
              </v-list-item>
  
              <v-list-group class="pt-0 mt-0" no-action>
                <template v-slot:activator>
                  <v-list-item-icon style="margin-right: -15px !important">
                    <v-icon class="pr-3" color="#9F9F9F">{{
                      dynamic.icon
                    }}</v-icon>
                    <v-list-item-title
                      style="
                        font-size: 14px;
                        font-family: poppinsmedium;
                        color: #9f9f9f;
                      "
                      :v-text="dynamic.title"
                    ></v-list-item-title>
                  </v-list-item-icon>
                </template>
  
                <v-list-item
                  v-for="child in dynamic.dynamicItems"
                  :key="child.title"
                  style="letter-spacing: 0.63px"
                  class="pr-0"
                  :to="child.path"
                >
                  <v-list-item-icon>
                    <v-list-item-title
                      class="pr-0"
                      style="
                        font-size: 14px;
                        font-family: poppinsmedium;
                        color: #9f9f9f;
                      "
                      :v-text="child.title"
                    ></v-list-item-title>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>
  
              <v-list-group class="pt-0 mt-0" no-action>
                <template v-slot:activator>
                  <v-list-item-icon style="margin-right: -15px !important">
                    <v-icon class="pr-3" color="#9F9F9F">{{
                      report.icon
                    }}</v-icon>
                    <v-list-item-title
                      style="
                        font-size: 14px;
                        font-family: poppinsmedium;
                        color: #9f9f9f;
                      "
                      :v-text="report.title"
                    ></v-list-item-title>
                  </v-list-item-icon>
                </template>
  
                <v-list-item
                  v-for="child in report.reportItems"
                  :key="child.title"
                  style="letter-spacing: 0.63px"
                  class="pr-0"
                  :to="child.path"
                >
                  <v-list-item-icon>
                    <v-list-item-title
                      class="pr-0"
                      style="
                        font-size: 14px;
                        font-family: poppinsmedium;
                        color: #9f9f9f;
                      "
                      :v-text="child.title"
                    ></v-list-item-title>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-navigation-drawer>
        </v-flex>
        <v-flex xs12 sm12 md10 lg10>
          <v-layout wrap pb-0 mb-0>
            <v-flex xs12 sm12 md12 lg12 pb-0 mb-0>
              <AppHeader class="pb-0 mb-0" />
            </v-flex>
            <v-flex xs12 pt-0 mt-0
              ><v-divider class="pt-0 mt-0"></v-divider
            ></v-flex>
            <v-flex xs12 sm12 md12 lg12 pl-3>
              <v-layout
                wrap
                justify-center
                :style="
                  $route.name == 'adminDashboard'
                    ? 'background-color: #ffffff'
                    : 'background-color: #f6fff9'
                "
              >
                <v-flex xs12>
                  <v-card
                    min-height="100vh"
                    :color="
                      $route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'
                    "
                    flat
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <router-view :key="$route.fullPath"></router-view>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import AppHeader from "@/components/Common/appHeader";
  
  export default {
    components: {
      AppHeader,
    },
    data() {
      return {
        items: [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard",
            path: "/Admin/admindashboard",
          },
          { title: "My Details", icon: "mdi-account", path: "/Admin/profile" },
          { title: "Public", icon: "mdi-account-group", path: "/Admin/public" },
          {
            title: "Conflict Reports",
            icon: "mdi-source-repository-multiple",
            path: "/Admin/ConflictReports",
          },
          {
            title: "Analytics",
            icon: "mdi-home-analytics",
            path: "/admin/analytics",
          },
          {
            title: "My Reports",
            icon: "mdi-file-chart",
            path: "/Admin/myReport",
          },
        ],
  
        report: {
          title: "Case Related",
          icon: "mdi-folder",
          visible: false,
          reportItems: [
            {
              title: "Report Case",
              path: "/Admin/reportCase",
            },
            {
              title: "Resolve Case",
              path: "/Admin/resolveCase",
            },
            {
              title: "Release Case",
              path: "/Admin/releaseCase",
            },
            
          ],
        },
        right: null,
      };
    },
    methods: {},
  };
  </script>
  